import React from "react"

import { PageProps } from "gatsby"

import { VehiclesPage } from "components/vehicles"

import { Audience } from "types/audience"

const Page = (props: PageProps) => {
  const vehicleId = props.params.vehicleId

  return (
    <VehiclesPage
      selectedVehicleId={parseInt(vehicleId)}
      audience={Audience.admin}
    />
  )
}

export default Page
